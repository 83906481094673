@import 'colors';

// Here you can add other styles
:root {
  --cui-border-radius: inherit;
}

body {
  color: $grayBase;
  font-family: 'Rubik', sans-serif;
}

.dark-theme .breadcrumb {
  --cui-breadcrumb-divider-color: rgba(255, 255, 255, 0.7);
  --cui-breadcrumb-item-active-color: rgba(255, 255, 255, 0.7);
}

.react-tel-input .country-list {
  color: $black;
  background-color: $white;
}

.form-switch .form-check-input {
  outline: solid;
  outline-width: thin;
}

.header-nav .nav-link {
  display: flex;
  align-items: center;
  gap: 16px;
}

// .header-nav .nav-link.active {
//   color: #2518ae;
// }

.avatar-img {
  border-radius: 100%;
}
.accountSettings {
  display: flex;
  align-items: center;
  gap: 4px;
}

svg.icon.icon-custom-size {
  width: 20px;
  height: 20px;
}

.customer {
  --cui-btn-font-size: 0.7rem;
}

li.list-group-item.flex.items-center.justify-between {
  color: inherit;
  background-color: inherit;
}

.table thead th {
  vertical-align: bottom;
  border: none;
  border-bottom: 2px solid #f2f3f7;
  padding-top: 0;
}

.react-tel-input .form-control {
  background: inherit !important;
  border: var(--cui-border-width) solid
    var(--cui-input-border-color, var(--cui-border-color)) !important;
  background-color: var(--cui-input-bg, var(--cui-body-bg)) !important;
}

.btn {
  height: 50px;
}

span.form-multi-select-tag {
  background-color: $primaryBlue;
  border-radius: 100px;
  padding: 6px 10px;
  color: white;
}

.table td {
  vertical-align: middle;
}

.form-multi-select-search {
  width: 100%;
}

.input-group .btn {
  z-index: 0;
}

#company-dropdown {
  width: 100%;
}

li:has(> #company-dropdown):hover,
li:has(> #company-dropdown):active {
  background-color: lightgray;
  width: 100%;
}

.header .dropdown:hover .dropdown-menu > #company-dropdown,
.header .dropdown .dropdown-menu > #company-dropdown {
  min-width: 250px;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(0px, 38.5px, 0px);
}

.header .dropdown:hover > .dropdown-menu,
.header .dropdown > .dropdown-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 34px, 0px);
}

a:hover {
  color: inherit;
}

.dark-theme .modal {
  --cui-modal-bg: #3d3e47;
  color: white;
  --cui-modal-color: #white;
}
